:root {
  --desktop: 768px; 
  --tablet: 425px;
  --nav-width: 100px;
  --m-nav-height: 85px;
  --coral: #FFAFC0;
  --forest: #0A4635;
  --color-c: #00c284;
  --color-d: #00a297;
  --color-e: #73FFFA;
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

h1, h2, h3, h4, h5, p, span, a {
  font-family: "Avenir";
}

/* Nav */
nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 85px;
  height: var(--m-nav-height);
  width: auto;
  text-align: left;
  background: none;
}

#Logo {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 10px;
  left: 8px;
  z-index: 100;
}

#Logo a, #Logo img {
  height: 50px;
  width: 50px;
}

/* Mobile Hamburger */

#Hamburger {
  width: 45px;
  height: 30px;
  position: absolute;
  top: 17px;
  right: 15px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 103;
}

#Hamburger span {
  display: block;
  position: absolute;
  height: 6px;
  width: 100%;
  background: #ff7261;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#Hamburger span:nth-child(1) {
  top: 0px;
}

#Hamburger span:nth-child(2) {
  top: 12px;
}

#Hamburger span:nth-child(3) {
  top: 24px;
}

#Hamburger.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

#Hamburger.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#Hamburger.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* END Mobile Hamburger */

#Nav-link-holder {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  background: rgba(0,0,0,.9);
  z-index: 102;
}

#Alt-logo {
  display: none;
  position: absolute;
  height: 50px;
  width: 50px;
  top: 10px;
  left: 8px;
}

#Alt-logo a, #Alt-logo img {
  height: 50px;
  width: 50px;
}

.menuOpen {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.menuOpen #Alt-logo {
  display: block !important;
}

nav ul {
  list-style-type: none;
  padding-left: 0;
  margin-left: 45px;
}

nav ul li {
  margin-bottom: 11px;
}

nav ul li a {
  font-size: 50px;
  font-weight: 300;
  text-decoration: none;
  color: #FFF;
  font-family: "BCAlphapipe";
}

/* END Nav */

/* Container */
.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f5f5f5;
}

/* END Container */

.page {
  position: relative;
  top: 60px;
  min-height: 75vh;
  margin-bottom: 60px;
}

.page-title {
  position: fixed;
  top: 9px;
  z-index: 2;
  margin-left: 60px;
  color: #FFAFC0;
  color: var(--coral);
  font-weight: 300;
  font-size: 30px;
  text-shadow: 0px 0px 5px #000;
  mix-blend-mode: difference;
  font-family: 'BCAlphapipe';
  font-weight: 500;
}

/* Home */
#Home {
 top: 0;
 bottom: 0;
 right: 0;
 left: 0;
 overflow-y: scroll;
 margin-left: 0;
 margin-bottom: 0;
}

#Lander {
  position: fixed;
  top: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100vh;
  overflow-y: hidden;
  z-index: 1;
}

#Lander canvas {
  -webkit-transform: translateX(-280px);
          transform: translateX(-280px);
}

#SplashImg {
  width: 100%;
  display: none;
}

#Splash {
  min-height: 100vh;
}

#Hello {
  z-index: 101;
  position: relative;
  mix-blend-mode: difference;
  color: #FFAFC0;
  padding-top: 45vh;
  right: 4%;
  left: 4%;
  width: 92%;
  font-weight: 700;
}

#Hello p {
  font-size: 8vh;
  font-family: 'Avenir';
  margin: 0;
}

h1 {
  font-size: 6vh;
  max-width: 555px;
  margin-bottom: 0;
}

h2 {
  font-size: 8vh;
  font-family: 'Avenir';
  margin-bottom: 60px;
  margin-top: 0;
}

.veil {
  position: relative;
  height: 120px;
  z-index: 2;
  background: -webkit-linear-gradient(top right, #fff0 0%, #fff0 50%, #EAFFE1 50%, #EAFFE1 100%);
  background: linear-gradient(to bottom left, #fff0 0%, #fff0 50%, #EAFFE1 50%, #EAFFE1 100%);
  mix-blend-mode: luminosity;
}

.antiveil {
  position: relative;
  height: 120px;
  z-index: 2;
  /*background: linear-gradient(to bottom left, #fff0 0%, #fff0 50%, var(--coral) 50%, var(--coral) 100%);*/
  background: -webkit-linear-gradient(bottom left, #fff0 0%, #fff0 50%, #EAFFE1 50%, #EAFFE1 100%);
  background: linear-gradient(to top right, #fff0 0%, #fff0 50%, #EAFFE1 50%, #EAFFE1 100%);
  mix-blend-mode: luminosity;
  margin-bottom: -120px;
}

.pveil {
  position: relative;
  height: 120px;
  z-index: 2;
  background: -webkit-linear-gradient(top right, #fff0 0%, #fff0 50%, #0A4635 50%, #0A4635 100%);
  background: linear-gradient(to bottom left, #fff0 0%, #fff0 50%, #0A4635 50%, #0A4635 100%);
  background: -webkit-linear-gradient(top right, #fff0 0%, #fff0 50%, var(--forest) 50%, var(--forest) 100%);
  background: linear-gradient(to bottom left, #fff0 0%, #fff0 50%, var(--forest) 50%, var(--forest) 100%);
  mix-blend-mode: exclusion;
}

.pantiveil {
  position: relative;
  height: 120px;
  z-index: 2;
  /*background: linear-gradient(to bottom left, #fff0 0%, #fff0 50%, var(--coral) 50%, var(--coral) 100%);*/
  background: -webkit-linear-gradient(bottom left, #fff0 0%, #fff0 50%, #0A4635 50%, #0A4635 100%);
  background: linear-gradient(to top right, #fff0 0%, #fff0 50%, #0A4635 50%, #0A4635 100%);
  background: -webkit-linear-gradient(bottom left, #fff0 0%, #fff0 50%, var(--forest) 50%, var(--forest) 100%);
  background: linear-gradient(to top right, #fff0 0%, #fff0 50%, var(--forest) 50%, var(--forest) 100%);
  mix-blend-mode: exclusion;
}

.what-text {
  position: relative;
  padding: 40px 8% 30px;
  font-size: 27px;
  z-index: 2;
  /*background-color: var(--coral);*/
  /*color: #FFF;*/
  background-color: #EAFFE1;
  color: #0A4635;
  color: var(--forest);
  mix-blend-mode: luminosity;
}

.what-text p {
  margin-top: 0;
}

.project-links {
  position: relative;
  padding: 40px 8% 30px;
  z-index: 2;
  background-color: #0A4635;
  background-color: var(--forest);
  mix-blend-mode: exclusion;
}

.pre, .post {
  min-width: 8%;
  width: 8%;
}

/*.project-links .links-holder {
  position: relative;
  z-index: 3;
}*/


.project-preview {
  min-height: 275px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: left;
          align-items: left;
  text-decoration: none;
  margin-bottom: 40px;
  padding: 40px 30px;
  font-size: 18px;
  font-weight: 400;
  mix-blend-mode: normal;
  transition: transform .2s ease,-webkit-transform .2s ease;
}
/*
.project-preview:last-child {
  margin-bottom: 0 !important;
}
*/
.project-preview:hover {
  -webkit-transform: scale(1.0125);
          transform: scale(1.0125);
}

.project-preview img {
  height: 200px;
  width: 200px;
  margin: 40px auto 0 auto;
}

.clapbacks {
  background-color: #ffe4c4;
}

.dual-power-map {
  /*background-image: url('/images/dual_power_map.png');*/
  background-color: #00c284;
  background-color: var(--color-c);
  color: #FFF;
}

.turing {
  background-color: #6c233b;
  color: #000;
  margin-bottom: 0;
}

#Skills {

}

#CreativePreview {
  min-height: 500px;
  position: relative;
  z-index: 2;
  background-color: #EAFFE1;
  mix-blend-mode: luminosity;
}


#Blog {

}

.post-text {
  margin: 0 30px 30px;
}

/* Work */
#Work {
  top: 6px;
}

.project-previews {
  position: relative;
  width: 100%;
  max-width: 1250px;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.project-previews .project {
  padding-top: 60px;
  position: relative;
}

.project img {
  width: 100%;
  height: auto;
}

.header-group {
  margin-top: 10px;
}

.project span {
  margin-left: 24px;
  display: inline-block;
}

.project .company {
  display: inline-block;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 1px;
}

.project .location {
  color: #666;
  font-weight: 300;
}

.project .website {
  color: #666;
  font-weight: 400;
  margin-bottom: 24px;
}

.role-span {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  padding-right: 10px;
}

.project .role {
  font-weight: 300;
  font-size: 21px;
  letter-spacing: 1px;
}

.project .timespan {
  margin-top: 4px;
  margin-right: 24px;
  font-weight: 300;
  font-family: "Helvetica Neue";
}

.project .achievements ul {
  padding-right: 20px;
}

.project .achievements ul li {
  font-family: "Helvetica Neue";
  line-height: 21px;
  margin-bottom: 3px;
}

.project .highlights ul {
  font-family: "Helvetica Neue";
  padding-left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: 21px;
}

.project .highlights ul li {
  list-style-type: none;
  border: 1px solid #FFF;
  color: #717171;
  font-weight: 500;
  font-size: 14px;
  padding: 3px 5px;
  margin-right: 8px;
  margin-bottom: 5px;
}

/* END Work */

/* Blog */

.post-previews {
  position: relative;
  margin-top: 9px;
  width: 100%;
  max-width: 1250px;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.post-preview {

}

.post-preview img {
  width: 100%;
  height: auto;
}

/* END Blog */

#Shop h2 {
  padding-top: 20%;
  width: 100%;
  text-align: center;
}

#Footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  z-index: 3;
  min-height: 25vh;
  color: #FFAFC0;
  color: var(--coral);
  mix-blend-mode: exclusion;
  width: 100%;
}

#Footer p {
  font-weight: 700;
}


@media screen and (min-width: 768px) {
  nav {
    width: 100px;
    width: var(--nav-width);
    bottom: 0;
    right: auto; 
    height: auto;
  }

  #Logo {
    height: 121px;
    width: 111px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 0;
  }

  #Logo a, #Logo img {
    height: 75px;
    width: 75px;
  }


  #Hamburger {
    display: none !important;
  }

  #Nav-link-holder {
    display: block;
    position: static;
    height: auto;
    background: none;
    position: absolute;
    top: 150px;
  }

  nav ul li a {
    font-size: 24px;
    color: #3f3f3f;
    font-weight: 400;
  }

  .page {
    margin-left: 100px;
    margin-left: var(--nav-width);
  }

  .page-title {
    margin-left: 0;
    top: 45px;
    /*position: fixed;
    z-index: 99;*/
  }

  #Lander {
    width: 100%;
  }

  #Lander canvas {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    width: 100%;
  }

  #Hello {
    padding-left: 250px;
    padding-right: 250px;
    padding-top: 50vh;
    left: 0;
    right: 0;
    width: auto;
  }

  .what-text {
    padding-left: 250px;
    padding-right: 250px;
  }

  .project-previews {
    margin-left: 50px;
    width: auto;
  }

  .project-links {
    padding-left: 250px;
    padding-right: 250px;
    width: auto;
  }

  .pre {
    min-width: 175px;
  }

  .post {
    min-width: 40px;
  }

  .project-links .links-holder {
    width: 100%;
  }

  .project-preview {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding-left: 75px;
    -webkit-align-items: center;
            align-items: center;
  }

  .project-preview img {
    margin: 36px;
  }

  .post-previews {
    margin-left: 50px;
    width: auto;
  }

  #Work {
    top: 0;
  }

  .project:first-child {
    padding-top: 0;
  }
}
/*
@media screen and (min-width: 900px) {
  #Lander canvas {
    width: 100%;
  }
}*/

/* END Container */

/* Home Page */

/* END Home Page */

/*.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
@font-face {
  font-family: 'BCAlphapipe';
  font-weight: 400;
  src: local('BCAlphapipe'), url(/static/media/BC-Alphapipe-Regular.52a87310.woff) format('woff');
}

@font-face {
  font-family: 'BCAlphapipe';
  font-weight: 300;
  src: local('BCAlphapipe'), url(/static/media/BC-Alphapipe-Light.32cddd8c.woff) format('woff');
}

@font-face {
  font-family: 'BCAlphapipe';
  font-weight: 200;
  src: local('BCAlphapipe'), url(/static/media/BC-Alphapipe-Thin.195c247c.woff) format('woff');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 200;
  src: url(/static/media/avenir-light-webfont.e7ad340e.woff) format('woff');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 300;
  src: url(/static/media/Avenir-Medium-webfont.019c82a4.woff) format('woff');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 400;
  src: url(/static/media/Avenir-Book.57483121.woff) format('woff');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 600;
  src: url(/static/media/Avenir-Heavy.0fff1b8a.woff) format('woff');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 700;
  src: url(/static/media/Avenir-Black-webfont.e41a7714.woff) format('woff');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Avenir', "Open Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

